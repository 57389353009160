import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  Body, Input, Logo, LpLayout, LoginForm,
  LoginButtons, LoginBtn, LoginBox
} from './styles/styles';
import Overlay from './Overlay';
import useApi from './api';

const Login = () => {
  const api = useApi();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('/login', { username, password });
      const { access_token } = response.data;
      localStorage.setItem('token', access_token);
      navigate('/istress');
    } catch (error) {
      alert('Error logging in');
      console.error(error);
    }
  };

  const handleRequestAccess = (data) => {
    // Handle the form submission from the overlay
    console.log(data);
    // Add your logic to handle the request access data
  };

  return (
    <LpLayout id="lp-layout">
      <LoginBox>
        <Logo>iStress</Logo>
        <LoginForm action="" className="login-form" name="login-form" id="login-form" onSubmit={handleSubmit}>
          <Input
            type=""
            id="lb-email"
            aria-label="Username"
            name="Username"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <Input
            type="password"
            id="lb-pass"
            aria-label="Password"
            name="Password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Input type="submit" hidden />
        </LoginForm>
        <LoginButtons>
          <LoginBtn className="login-btn" id="login-btn" onClick={handleSubmit}>
            Login
          </LoginBtn>
          <LoginBtn className="request-access-btn" id="request-access-btn" onClick={() => { setIsOverlayVisible(true) }}>
            Request access  
          </LoginBtn>
        </LoginButtons>
      </LoginBox>
      {isOverlayVisible && (
        <Overlay
          onClose={() => setIsOverlayVisible(false)}
          onSubmit={handleRequestAccess}
        />
      )}
    </LpLayout>
  );
};

const makePopup = (error) => {
  if (error) {
    // Implement your popup logic here
    console.error(error);
  }
};

export default Login;
