import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { FileForm, Logo, ChooseButton, Label, Subtitle, SubmitButton, Message, Result, Labeldiv, StyledForm, Results,
    ButtonReal, FileName, GraphImage, Container, GraphGrid, GraphCard, Prompt, UserInputField, UserInputForm, PromptForm, EnterButton, GraphTitle, CommandBox
 } from './styles/styles' 
import UserInput from './UserInput'; 
import useApi from './api';
import placeholder0 from '../assets/images/Placeholder_0.jpg';
import placeholder1 from '../assets/images/Placeholder_1.jpg';
import placeholder2 from '../assets/images/Placeholder_2.jpg';
import placeholder3 from '../assets/images/Placeholder_3.jpg';

const FileUpload = () => {
  const api = useApi();
  const [txtFile, setTxtFile] = useState(null);
  const [excelFile, setExcelFile] = useState(null);
  const [txtFileName, setTxtFileName] = useState('');
  const [excelFileName, setExcelFileName] = useState('');
  const [message, setMessage] = useState('');
  const [result, setResult] = useState('');
  const [resultData, setResultData] = useState(null);
  const hiddenTxtFileInput = useRef(null); 
  const hiddenExcelFileInput = useRef(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [prompts, setPrompts] = useState('');
  const [userInputs, setUserInputs] = useState([]);
  const [graphs, setGraphs] = useState([]);
  const titles = ["Borehole Deformation", "Outlier Limit", "BO Rotation Angle", "Fitting BO Width"];
  const [buttonText, setButtonText] = useState('Run'); 
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [submitButtonPressed, setSubmitButtonPressed] = useState(Array(4).fill(false));
  const [inputFieldDisabled, setInputFieldDisabled] = useState(Array(4).fill(false));
  const placeholders = [placeholder0, placeholder1, placeholder2, placeholder3];

  useEffect(() => {
    setGraphs([]);
  }, []);
  
  const handleExcelClick = event => {
    hiddenExcelFileInput.current.click();    
  };

  const handleTxtClick = event => {
    hiddenTxtFileInput.current.click();    
  };

  const handleTxtFileChange = (e) => {
    const file = e.target.files[0];
    setTxtFile(file);
    setTxtFileName(file.name);
  };

  const handleExcelFileChange = (e) => {
    const file = e.target.files[0];
    setExcelFile(file);
    setExcelFileName(file.name);
  };

  const resetState = () => {
    setMessage('');
    setResult('');
    setResultData(null);
    setPrompts([]);
    setUserInputs([]);
    setGraphs([]);
    setButtonText('Running');
    setSubmitButtonPressed(Array(4).fill(false));
    setInputFieldDisabled(Array(4).fill(false));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if ((!txtFile || !excelFile) && !formSubmitted) {
      setMessage('Both files are required');
      setButtonText('Run');
      setIsButtonDisabled(false);
      return;
    }

    resetState(); 
    setFormSubmitted(true);

    const formData = new FormData();
    formData.append('txt_file', txtFile);
    formData.append('excel_file', excelFile);

    try {
      const response = await api.post('/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const newUserInputs = [];

      if (response.data.prompt) {
        if (response.data.prompt === 'No prompt') {
          // setPrompts([...prompts, '']);

          setGraphs(prevGraphs => {
            const newGraphs = [...prevGraphs];
            newGraphs[0] = response.data.graph; // Ensure the correct graph index is set
            return newGraphs;
          });
          handleNextStageSubmit(newUserInputs);
        }

        else if (response.data.graph !== null) { // Check if graph is not null
          setGraphs([...graphs, response.data.graph]); // Add new graph to the array
          setPrompts([...prompts, response.data.prompt]);
        }
      } else {
        setMessage(response.data.message);
      }
    } catch (error) {
      setMessage('Error uploading files');
      console.error(error);
    }
    finally {
      setButtonText('Run'); // Reset button text after submission is complete
      setIsButtonDisabled(false); // Re-enable the button after submission is complete

    }
  };
  
  const handleUserInputSubmit = async (e, index) => {
    e.preventDefault();
    setSubmitButtonPressed(prevState => {
      const newState = [...prevState];
      newState[index] = true;
      return newState;
    });
    setInputFieldDisabled(prevState => {
      const newState = [...prevState];
      newState[index] = true;
      return newState;
    });

    try {
      const input = e.target.userInput.value;
      const newUserInputs = [...userInputs];
      newUserInputs[0] = 0;
      newUserInputs[index] = input; // Save the latest input
      setUserInputs(newUserInputs);

      const response = await api.post('/submit_input', {
        txt_filename: txtFileName,
        excel_filename: excelFileName,
        user_inputs: newUserInputs,
        stage: index + 1
      });
      
      if (response.data.prompt) {
          // setGraphs([...graphs, response.data.graph]); // Add new graph to the array
        console.log(response)
        console.log(response.data)
        console.log(`Graph at index ${index}:`, response.data.graph);
        if (index + 1 === 4) {
          setGraphs(prevGraphs => {
            const newGraphs = [...prevGraphs];
            newGraphs[3] = response.data.graph;
            return newGraphs;
          });
        }
        setGraphs(prevGraphs => {
          const newGraphs = [...prevGraphs, response.data.graph];
          console.log('Updated graphs array:', newGraphs);
          return newGraphs;
        });

        setPrompts([...prompts, response.data.prompt]);

        if(response.data.result) {
          setResultData(response.data.result); 
        }
      }
    } catch (error) {
      setMessage('Error processing user input');
      console.error(error);
    }
  };

  const handleNextStageSubmit = async (userInputs) => {
    try {
        const res = await api.post('/submit_input', {
            txt_filename: txtFileName,
            excel_filename: excelFileName,
            user_inputs: userInputs,
            stage: 1
        });

        setGraphs(prevGraphs => {
          const newGraphs = [...prevGraphs];
          newGraphs[1] = res.data.graph;
          return newGraphs;
        });

        if (res.data.prompt) {
          setPrompts([null, res.data.prompt]);
        }
        return;
        
    } catch (error) {
        console.error('Error submitting next stage', error);
    }
};

const renderResult = (result) => {
  return (
    <CommandBox>
      <Result>
        <ul>
            {Object.keys(result).map((key) => (
                <li key={key} style={{ marginBottom: '40px' }}>
                    <strong>{key}:</strong> {Array.isArray(result[key]) ? result[key].join(', ') : result[key]}
                </li>
            ))}
        </ul>
        
      </Result>
    </CommandBox>
  );
};

  return (
    <Container>
      <FileForm>
        <Logo>iStress</Logo>
        <Subtitle>Reverse faulting regime</Subtitle>
        <StyledForm onSubmit={handleSubmit}>
          <Labeldiv>
            <Label>Import Properties</Label>
            <ButtonReal onClick={handleTxtClick}> Choose File </ButtonReal>
            <ChooseButton type="file" accept=".txt" onChange={handleTxtFileChange} ref={hiddenTxtFileInput}/>
            {txtFileName && <FileName>{txtFileName}</FileName>}
          </Labeldiv>
          <Labeldiv>
            <Label>Import ATV Data</Label>
            <ButtonReal onClick={handleExcelClick}> Choose File </ButtonReal>
            <ChooseButton type="file" accept=".xlsx,.xls" onChange={handleExcelFileChange} ref={hiddenExcelFileInput}/>
            {excelFileName && <FileName>{excelFileName}</FileName>}
          </Labeldiv>
          <SubmitButton type="submit" disabled={isButtonDisabled}>{buttonText}</SubmitButton>
        </StyledForm>
        {message && <Message>{message}</Message>}
      </FileForm>


      <GraphGrid>
        {Array.from({ length: 4 }).map((_, index) => (         
          <GraphCard key={index}>
            <GraphTitle>{titles[index]}</GraphTitle> {/* Centered and with some margin */}

            {/* {graphs[index] && <GraphImage src={`data:image/jpeg;base64,${graphs[index]}`} alt={`Graph ${index + 1}`} />} */}
            {graphs[index] ? (
              <GraphImage src={`data:image/jpeg;base64,${graphs[index]}`} alt={`Graph ${index + 1}`} />
            ) : (
              <GraphImage src={placeholders[index]} alt={`Placeholder ${index + 1}`} />
            )}

            <PromptForm>
            {prompts[index] && (
              <>
                <Prompt>{prompts[index]}</Prompt>
                {prompts[index] !== 'No prompt' && (
                  <UserInputForm onSubmit={(e) => handleUserInputSubmit(e, index)}>
                  <div>
                    <UserInputField name="userInput" required disabled={inputFieldDisabled[index]} />
                    {!submitButtonPressed[index] && (
                      <EnterButton type="submit">Submit</EnterButton>
                    )}
                  </div>
                  </UserInputForm>
                )}
              </>
            )}
            </PromptForm>
          </GraphCard>
        ))}
      </GraphGrid>

      <Results>  
        <h3>Results</h3>
        
        {resultData ? (
          renderResult(resultData)  // Render result data if available
        ) : (
          <CommandBox>
          <ul>
            <li style={{ marginBottom: '40px' }}><strong>Max Allowable Sicma H:</strong> 22.5</li>
            <li style={{ marginBottom: '40px' }}><strong>Min Allowable Sicma h:</strong> 3.9</li>
            <li style={{ marginBottom: '40px' }}><strong>Sicma H:</strong> 12.8, 13.0</li>
            <li style={{ marginBottom: '40px' }}><strong>Sicma h:</strong> 6.5, 7.8</li>
            <li style={{ marginBottom: '40px' }}><strong>Sicma v:</strong> 3.9</li>
          </ul> 
          </CommandBox>
        )}
        {/* {resultData && renderResult(resultData)} Render result data */}
      </Results>

    </Container>
  );
};

export default FileUpload;
