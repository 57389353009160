
import React, { useState } from 'react';
import useApi from './api';
import {
    OverlayContainer, OverlayContent, Label, Input, LoginBtn, LoginButtons
  } from './styles/styles';

const Overlay = ({ onClose, onSubmit }) => {
    const api = useApi();   
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [message, setMessage] = useState('');
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      onSubmit({ email, name, companyName, message });
      await api.post('/send-email', { email, name, companyName, message });
      onClose();
    };
  
    return (
      <OverlayContainer>
        <OverlayContent>
          <Label>Request Access</Label>
          <form onSubmit={handleSubmit} style={{ width: '80%' }}>
            <div>
              <label style={{ fontSize: '20px', fontWeight: 'bold' }}>Email:</label>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                style={{ fontSize: '20px', marginBottom: '10px', height: '30px' }}
              />
            </div>
            <div>
              <label style={{ fontSize: '20px', fontWeight: 'bold' }}>Name:</label>
              <Input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                style={{ fontSize: '20px', marginBottom: '10px', height: '30px' }}
              />
            </div>
            <div>
              <label style={{ fontSize: '20px', fontWeight: 'bold' }}>Company Name:</label>
              <Input
                type="text"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                required
                style={{ fontSize: '20px', marginBottom: '10px', height: '30px' }}
              />
            </div>
            <div>
              <label style={{ fontSize: '20px', fontWeight: 'bold' }}>Message:</label>
              <Input
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
                style={{ fontSize: '20px', marginBottom: '10px', height: '30px' }}
              />
            </div>
            <LoginButtons className="form-actions">
              <LoginBtn style={{backgroundColor:"#007acc"}} type="submit">Submit</LoginBtn>
              <LoginBtn type="button" onClick={onClose}>Close</LoginBtn>
            </LoginButtons>
          </form>
        </OverlayContent>
      </OverlayContainer>
    );
  };

  export default Overlay;
