import { Routes, Route } from "react-router-dom";

import Login from "./components/Login.jsx";
import { AppDiv } from "./components/styles/styles";
import FileUpload from "./components/File.jsx";
import ProtectedRoute from "./components/ProtectRoute.jsx";

function App() {
  return (
    <AppDiv>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/istress" element={<ProtectedRoute><FileUpload /></ProtectedRoute>} /> 
      </Routes>
    </AppDiv>
  );
}

export default App;