import { styled, createGlobalStyle } from 'styled-components';

export const Body = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
    font-family: Arial;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: #F5DFD4;
    height: 100%;
    width: 100%;
  }
` 

export const AppDiv = styled.div`
    margin: 0;
    padding: 0;
    font-family: Arial;
    background-color: #F5DFD4;
    height: 100%;
` 
export const LpLayout = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const Logo = styled.div`
  color: #007acc;
  font-size: 3em;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  padding: 10px 10px;
  border-radius: 20px;

  @media (max-width: 1000px) {
    font-size: 2em;
  }

  @media (min-width: 1700px) {
    font-size: 3.8em;
  }
`
export const Input = styled.input`
  border-radius: 10px;
  border: 1px solid #f0f1f3;
  background-color: #f0f1f3;
  width: 70%;
  height: 100%;
  font-size: 1.2em;

  @media (min-width: 1700px) {
    font-size: 1.5em;
  }
`;

export const OverlayContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 1700px) {
    height: 120%;
    top: -17%;
  }
`;

export const OverlayContent = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 30%;
  height: 35%;
  justify-content: center;
  align-items: center;
  gap: 1em;
`;


export const LoginForm = styled.form`
display: flex;
flex-direction: column;
gap: 10px;
width: 100%;
height: 25%;
align-items: center;
margin-bottom: 5%;
`

export const LoginButtons = styled.div`
  margin: 10px 0;
  display: flex;
  column-gap: 5px;
  flex-direction: column;
`

export const LoginBtn = styled.button`
  padding: 10px 10px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 15px;

  &.login-btn {
    background-color: #F5DFD4;
    color: black;
  }

  &.request-access-btn {
    background-color: #fff;
    color: #6c757d;
  }

   @media (min-width: 1700px) {
      font-size: 1.4em;
  }
`

export const LoginBox = styled.div`
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  box-shadow: 1px 1px 10px rgb(206, 206, 206);
  width: 25%;
  height: 40%;
  border-radius: 20px;
  background-color: rgb(255, 255, 255);
  margin-bottom: 20px;
  padding: 20px;
`

export const FileForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  gap: 10px;
  height: 85%;
  width: 20%;
  border-radius: 50px;
  border: 1px solid #f0f1f3;
  margin-left: 5%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #f1f1f1;
  margin-top: 3%;
`

export const Labeldiv = styled.div`
  width: 100%;
  height: 35%;
  justify-content: center;
  align-items: center;
  align-content: center;
  display: flex;
  flex-direction: column;
`;

export const StyledForm = styled.form`
  height: 100%;
  width: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Subtitle = styled.h2`
  font-size: 1em;
  color: #007acc;
  margin-top: 0px;
  margin-bottom: 20px;
  @media (max-width: 1000px) {
    font-size: 0.6em;
    margin-top: -10px;
  }
  @media (min-width: 1700px) {
    font-size: 1.3em;
  }
`;

export const Label = styled.label`
  font-size: 1.4em;
  margin-bottom: 5px;
  color: #007acc;
  font-weight: bold;
  text-align: left;
  width: 100%;
  align-content: center;
  text-align: center;
  position: relative;
  @media (max-width: 1000px) {
    font-size: 1em;
  }
  @media (min-width: 1700px) {
    font-size: 1.8em;
  }
`;

export const ChooseButton = styled.input`
  display: none;
`;

export const ButtonReal = styled.button`
  cursor: pointer;
  font: inherit;
  height: 50px;
  width: 80%;
  margin-top: 20px;
  border-radius: 10px;
  font-size: 1.2em;
  position: relative;
  @media (max-width: 1000px) {
    font-size: 1em;
  }

  @media (min-width: 1700px) {
    font-size: 1.8em;
    height: 80px;
  }
`;

export const SubmitButton = styled.button`
  background-color: #000;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
  width: 80%;
  font-size: 1.2em;
  border-radius: 10px;  
  &:hover {
  background-color: #333;
  position: relative;
  }

  @media (min-width: 1700px) {
    font-size: 2.3em;
  }
`;


export const Message = styled.p`
  font-size: 0.9em;
  color: #007acc;
  margin-top: 10px;
  @media (min-width: 1700px) {
    font-size: 1.3em;
  }
`;

export const Result = styled.div`
  // font-size: 1em;
  color: #007acc;
  margin-top: 5%;
  margin-right: 5%;
`;

export const FileName = styled.p`
  font-size: 1em;
  color: #28a745;
  margin-top: 10px;
  @media (min-width: 1700px) {
    font-size: 1.3em;
  }
` 

export const UserInputSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;


export const GraphImage = styled.img`
  margin-top: 20px;
  width: auto;
  height: 78%;
  margin-top: -10px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 1000px) {
    height: 60%;
    width: auto;
  }

  @media (min-width: 1700px) {
    height: 82%;  
  }
`;

export const Container = styled.div`
  display: flex;
  height: 100vh;
  align-items: flex-start;
  gap: 20px;
  overflow: hidden;
`;

export const GraphGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 2%;
  width: 80%;
  height: calc(100% - 30px);
  margin-top: 1%;

  @media (max-width: 700px) {
    grid-template-columns: 1fr; 
    grid-template-rows: auto; 
    width: 100%;
  }
`;

export const GraphCard = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex-start;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%; 
  min-height: 300px; 
  overflow: hidden;
  object-fit: contain;
`;

export const Prompt = styled.p`
  font-size: 0.8em;
  color: #007acc;
  margin-top: 5px;
  margin-bottom: 1px;
  @media (min-width: 1700px) {
    font-size: 1em;
  }
`;

export const UserInputField = styled.input`
  border: 2px solid #ccc;
  border-radius: 5px;
  width: 30%;
  font-size: 0.6em;
  margin-right: 5px;
  margin-left:3px;
  padding: 3px;
  @media (min-width: 1700px) {
    font-size: 1em;
  }
`;

export const UserInputForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 37%;
`;

export const EnterButton = styled.button`
  background-color: #000;
  color: #fff;
  padding: 4px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
  width: 40%;
  font-size: 0.6em;
  border-radius: 10px;  
  &:hover {
    background-color: #333;
  }
  @media (min-width: 1700px) {
    font-size: 1em;
    width: 60%;
  }
`;

export const Results = styled.div`
  // border: 2px solid #ccc;
  margin-right: 2%;
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 1em;
  color: #007acc;
  width: 25%;
  height: 85%;
  border-radius: 50px;
  margin-top: 3%;
  border: 1px solid #f0f1f3;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #f1f1f1;
  @media (min-width: 1700px) {
    font-size: 1.6em;
  }
`;

export const CommandBox = styled.div`
  background-color: #fff;
  border: 2px solid #ccc;
  width: 85%;
  height: 80%;
`

export const GraphTitle = styled.p`
  position: relative;
  margin-top: 7px;
  margin-bottom: 10px;
  @media (min-width: 1700px) {
      font-size: 1.8em;
  }
`

export const PromptForm = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  // position: relative;
  // bottom: 0px;
`