import axios from 'axios';

const useApi = () => {
  // Create an instance of axios
  const api = axios.create({
    baseURL: 'https://istress.cloud',
    // baseURL: 'http://127.0.0.1:5000',

  });
   //WATCHPACK_POLLING=true
  api.interceptors.request.use(
    config => {
      const token = localStorage.getItem('token');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    error => Promise.reject(error)
  );

  // Add a response interceptor
  api.interceptors.response.use(
    response => response,
    error => {
      if (error.response && error.response.status === 401) {
        // Token has expired or user is not authorized
        localStorage.removeItem('token'); // Remove the expired token
        window.location.href = '/'; // Redirect to login page
      }
      return Promise.reject(error);
    }
  );

  return api;
};

export default useApi;
